.tenant-hover:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.setting-boarder {
    border-radius: 20px;
}

.input-field {
    max-width: 500px;
}

.bold-form-labels .form-label {
    font-weight: bold;
}

.dangerous-rounded-box {
    border: 1px solid red;
    border-radius: 8px;
    padding: 20px;
}

/* Size chart table */
.size-chart-table {
    margin-left: auto;
    margin-right: auto;
}

.size-chart-cell {
    text-align: center;
    vertical-align: middle;
}

.size-chart-table-row td {
    /*padding: 5px 0 0 5px; !* Adjust padding as needed *!*/
    /*margin: 0;*/
    height:40px
}

.size-chart-container {
    overflow-x: auto;
    width: 100%;
}


.thumbnail-img {
    max-width: 64px;
    max-height: 64px;
    object-fit: contain;
}
