@import '@progress/kendo-theme-core/scss/functions/index.import.scss';

$kendo-colors: ();
$kendo-colors: k-map-merge(
                $kendo-colors,
                k-generate-color-variations('primary', #2B2BB2, 'default'),
                k-map-set($kendo-colors, on-app-surface, #00216B)
);

//$kendo-enable-typography: true;
$kendo-font-size: 1rem;
$kendo-font-family: Helvetica;

.k-column-title {
  font-weight: 700;
  font-size: 14px;
}

$kendo-grid-alt-bg: #DCECFF;

@import '@progress/kendo-theme-default/dist/all.scss';