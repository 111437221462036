
.nowrap-grid {
    height: calc(100vh - 200px);
}

.nowrap-grid .k-grid-content td {
    white-space: nowrap;
    overflow-y: hidden;
    text-overflow: ellipsis;
}

.no-scroll-grid .k-grid-header {
    padding-inline-end: 0;
}

.no-scroll-grid .k-grid-content.k-virtual-content{
    overflow-y: hidden;
}

.k-grid td, .k-grid th {
    border-left: none !important;/* Left border */
    border-right: 1px solid #ccc !important; /* Right border */
    border-top: none !important; /* Remove top border */
    border-bottom: none !important; /* Remove bottom border */
}