.editable-input-group {
    width: 100%; /* You can adjust this value to your desired fixed width */
    /*background: #f4f6fa;*/
    border-radius: 15px;
}

.input-group-text{
    width: 100%;
    border: None;
    background: transparent;
}
.form-control {
    width: 100%;
}


.input-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    /*min-height: calc(1.5em + 0.75rem + 2px);*/
}


/* Button css*/
.editable-input-group .btn-outline-secondary,
.editable-input-group .btn-outline-danger {
    border: none;
}

.editable-input-group .btn-outline-secondary:hover,
.editable-input-group .btn-outline-danger:hover {
    background-color: rgba(0, 0, 0, 0.1);
}


.check-btn {
    color: #28a745; /* You can change this to any green color you like */
}

.check-btn:hover {
    background-color: rgba(40, 167, 69, 0.1); /* This creates a light green background on hover */
}

